.Moods {
    .Title {
        display: flex;
        justify-content: center;
    }
    .CardGridItem .title-text {
        .light-theme & {
            color: $black;
        }
    }
}
