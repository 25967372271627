.CardGrid {
    display: grid;
    justify-content: center;
    padding-left: 2.64rem;
    margin: -1.32rem 0 -1.32rem 0;
    .CardGridItem {
        height: 100%;
        margin: 1.32rem;
        cursor: pointer;
        transition: all 0.15s ease-in-out;
        .title-text {
            transition: all 0.15s ease-in-out;
        }
        &:hover {
            .title-text {
                color: $primary;
            }
        }
        & img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        .title-text {
            color: $white;
        }
    }
}
