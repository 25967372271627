.SyncList {
    .versions-dropdown {
        padding-left: 5.6rem;
        .song-version {
            display: flex;
            align-items: center;
            padding-top: 0.6rem;
            .wave-form-container {
                max-width: 30.1rem;
            }
            .download-container {
                margin-top: 0.5rem;
                &:hover {
                    filter: $crimson-bright-filter;
                }
                .light-theme & {
                    // Grey color
                    filter: brightness(0) saturate(100%) invert(81%) sepia(11%) saturate(10%) hue-rotate(334deg)
                        brightness(95%) contrast(88%);
                    &:hover {
                        filter: $crimson-bright-filter;
                    }
                }
                .download-icon {
                    filter: $white-filter;
                    cursor: pointer;
                }
            }
        }
        .version-text {
            color: $white;
            width: 2rem;
            margin-top: 0.5rem;
            .light-theme & {
                color: $black;
            }
        }
    }
    .list-item {
        &.header .versions-chevron {
            visibility: hidden;
        }
        .versions-chevron {
            cursor: pointer;
            padding: 1rem;
            margin-right: 1rem;
            transform: scale(1.3) rotate(90deg);
            transition: all 300ms;
            &.rotate {
                transform: scale(1.3) rotate(0deg);
            }
            .light-theme & {
                filter: invert(1);
            }
        }

        .midi-icon {
            display: none;
        }
        .key-bpm-time-container {
            grid-template-columns: 50px 70px 0px;
            flex: 0;
            .key {
                display: none;
            }
        }
        .icons-container {
            margin-left: 0;
            justify-content: center;
        }
        .sl-genres-container {
            color: $night-theme-text;
            min-width: 1;
            max-width: 200px;
            flex: 2.4;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            .sl-genre {
            }
        }
    }
    .version-play-container {
        position: relative;
        .version-select-menu {
            position: absolute;
            top: 1rem;
            left: -10rem;
            // left: 6rem;
            display: none;
        }
        &:hover {
            .version-select-menu {
                margin-top: -2rem;
                display: block;
                z-index: 99;
                padding-top: 2rem;
            }
        }
    }

    .version-download-container {
        position: relative;
        .version-select-menu {
            position: absolute;
            top: 5rem;
            display: none;
        }
        &:hover {
            .version-select-menu {
                margin-top: -2rem;
                display: block;
                z-index: 99;
                padding-top: 2rem;
            }
        }
    }
}
