.Account {
    .top {
        .profile-container {
            display: flex;
            flex-direction: column;
            width: 467px;
            padding: 0 2rem;
            @media all and (max-width: 500px) {
                width: 100%;
            }
            .title {
            }
            .picture {
                position: relative;
                margin-top: 1rem;
                margin-bottom: 5rem;
            }
            .edit-icon {
                position: absolute;
                width: 25px;
                bottom: 0.7rem;
                left: 6.2rem;
                cursor: pointer;
                pointer-events: none;
            }
            .profile-image {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                object-fit: cover;
                background: $black;
                .light-theme & {
                    background: rgba(234, 234, 234, 0.6);
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .input {
            height: 47px;
            border-radius: 6px;
            background-color: $white;
            // .day-mode & {
            //     background-color: map-get($map: $theme-colors, $key: 'DAY_MODE_GREY');
            // }
            font-size: 0.875rem;
            color: $black;
            border: none;
            padding: 0px 16px;
            max-width: 140px;
            display: flex;
            flex: 1;
        }
    }

    // General header text, for forms and FAQ.
    .header {
        color: $white;
        font-size: 2rem;
        font-family: 'Poppins-semi-bold';
        margin-bottom: 2rem;
        &.changepw {
            margin-top: 6.5rem;
            .light-theme & {
                color: $black;
            }
        }
        .light-theme & {
            color: $black;
        }
    }

    input {
        background: transparent;
        border: 1px solid #343434;
        border-radius: 0.7rem;
        padding: 1rem 2rem;
        width: 100%;
        height: 5rem;
        color: white;
        font-size: 1.4rem;
        .light-theme & {
            color: $black;
            border: 1px solid #e1e1e1;
        }
    }
    label {
        color: map-get($light-theme, text-grey);
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 1.6rem;
    }

    // FORM Section
    .top {
        padding: 10rem 0 15rem 0;
        width: 100%;
        background: #1a1a1a;
        align-items: center;
        display: flex;
        flex-direction: column;
        .light-theme & {
            background: $white;
        }
        form {
            display: flex;
            flex-direction: column;
            width: 467px;
            padding: 0 2rem;
            @media all and (max-width: 500px) {
                width: 100%;
            }
        }
        .save-btn,
        .change-btn {
            min-width: 112px;
            background: $primary;
            border-radius: 0.5rem;
            font-size: 1.8rem;
            margin-top: 3rem;
            margin-right: auto;
        }
    }
    .faq-container {
        padding-bottom: 15rem;
    }
}
