.HeaderBar {
    display: flex;
    height: $header-height;
    background-color: $black;
    align-items: center;
    padding: 0rem 4rem 0rem 24px;
    position: fixed;
    width: 100%;
    z-index: 999;
    .suggestion {
        background: pink;
    }
    .light-theme & {
        background-color: map-get($light-theme, white);
        border-bottom: 1px solid #e6e6e6;
    }
    @media screen and (max-width: $bpm-brk-6) {
        padding: 0 1.5rem;
    }
    @media screen and (max-width: $bpm-brk-3) {
        &:not(.burgerOpen) {
            justify-content: space-between;
        }
    }
    *.hide {
        display: none;
    }
    .right-container {
        display: flex;
        align-items: center;
        transform: scale(0.85);
        margin-right: -3rem;
        @media screen and (max-width: $bpm-brk-3) {
            margin-right: -1rem;
        }
    }

    .logo {
        // icon only
        &-mobile {
            cursor: pointer;
            width: 14rem;
            margin-right: 6rem;
            height: 34px;
            @media screen and (max-width: 1150px) {
                margin-right: 0;
            }
            @media screen and (max-width: $bpm-brk-4) {
                display: none;
            }
            @media screen and (max-width: $bpm-brk-4) {
                margin-left: 0;
            }
        }
        // icon + text
        &-pc {
            cursor: pointer;
            display: none;
            width: 3.5rem;
            @media screen and (max-width: $bpm-brk-4) {
                display: unset;
            }
        }
    }

    .username {
        color: $white;
        margin-left: 2rem;
        font-size: 1.7rem;
        .light-theme & {
            color: $black;
        }
    }

    .search-container {
        position: relative;
        margin-left: 8.5rem;
        z-index: 101;
        @media screen and (min-width: $bpm-brk-3) {
            margin-right: auto;
        }
        &.search-expanded {
            width: 100%;
            @media screen and (max-width: $bpm-brk-3) {
                width: 60%;
            }
            @media screen and (max-width: $bpm-brk-4) {
                width: 100%;
            }
        }
        @media screen and (max-width: $bpm-brk-3) {
            margin-left: 2rem;
            width: 25rem;
            &:not(.search-expanded) {
                display: none;
            }
        }

        @media screen and (max-width: $bpm-brk-6) {
            &.search-expanded {
                width: 80%;
            }
            .search-container {
                margin-left: unset;
            }
        }
    }
    .search-bordered {
        border: none;
        border-bottom: 1px solid $search-grey;
        .light-theme & {
            border-bottom: 1px solid map-get($light-theme, divider-grey);
        }
    }

    .mobile-magnifying-glass {
        filter: $crimson-bright-filter;
        right: 24rem;

        cursor: pointer;
        z-index: 99;
        display: none;
        margin-left: auto;
        margin-right: -1.36rem;

        @media screen and (max-width: $bpm-brk-3) {
            display: unset;
        }
        @media screen and (max-width: $bpm-brk-6) {
            right: 21.5rem;
        }
    }

    .magnifying-glass {
        position: absolute;
        fill: $night-theme-text;
        top: 1rem;
        height: 1.5rem;
        left: 1rem;
        z-index: 2;
        margin-right: -1.36rem;
        @media screen and (max-width: $bpm-brk-3) {
            margin-top: 2rem;
            font-size: 2rem;
            height: 2rem;
            top: -1rem;
        }
    }

    .cancel {
        width: 2.5rem;
        transform: rotate(135deg);
        filter: $crimson-bright-filter;
        position: absolute;
        right: 1rem;
        top: 0.9rem;
        cursor: pointer;
        @media screen and (min-width: $bpm-brk-4) {
            // right: 25.5rem;
        }
    }

    .cancel-grey {
        @extend .dropdown-btn;
        transform: rotate(135deg) !important;
        right: 4rem !important;
        @media all and (max-width: $bpm-brk-3) {
            display: none !important;
        }
    }

    .dropdown-btn {
        position: absolute;
        right: 1.2rem;
        top: 1.2rem;
        width: 1.3rem;
        filter: $night-theme-text-filter;
        transform: rotate(-90deg);
        //transition: transform .2s;
        cursor: pointer;
        @media all and (max-width: $bpm-brk-3) {
            display: none;
        }
        &.reverse {
            transform: rotate(90deg);
        }
    }

    .mobile-cancel {
        @extend .cancel;
        top: 2.2rem;
        right: 3.4rem;
        width: 3rem;
    }
    .search-bar-container {
        position: relative;
        padding: 0 5rem 0.1rem 3.5rem;
        background: #222222;
        border-radius: 0.7rem;
        @media screen and (max-width: 1150px) {
            padding: 0 0rem 0.1rem 3.5rem;
        }
        @media screen and (max-width: $bpm-brk-3) {
            width: 100%;
        }
        @media screen and (max-width: $bpm-brk-4) {
            padding-left: 4.6rem;
        }
        .light-theme & {
            caret-color: $black;
            color: $black;
            background: rgba(#eaeaea, 0.5);
        }
        input.search-bar {
            width: 32rem;
            margin-top: 0.1rem;
            @media screen and (max-width: $bpm-brk-3) {
                width: 25rem;
                margin-top: 0.4rem;
            }
        }

        .search-bar {
            height: 3.5rem;
            font-size: 2rem;
            border: none;
            outline: none;
            background-image: none;
            background-color: transparent;
            -webkit-appearance: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            color: $white;
            font-size: 1.4rem;
            .light-theme & {
                caret-color: $black;
                color: $grey;
            }
            @media screen and (max-width: $bpm-brk-3) {
                font-size: 2rem;
                padding-bottom: 0.5rem;
                margin-top: 0.5rem;
                width: 100% !important;
            }
        }

        .search-bar::placeholder {
            color: $night-theme-text;
        }

        .react-autosuggest__container {
            @media screen and (max-width: $bpm-brk-3) {
                width: 100%;
            }
        }

        .react-autosuggest__suggestions-container {
            position: absolute;
            background-color: $black;
            z-index: 20;
            left: 0;
            width: 100%;
            .light-theme & {
                background-color: white;
            }
            .react-autosuggest__suggestion {
                cursor: pointer;
                padding: 0.8rem 1rem;
                font-weight: 500;
                color: $white;

                .light-theme & {
                    caret-color: $black;
                    color: $grey;
                    .suggestion {
                        background: $primary;
                        color: $white;
                        border: none;
                        outline: none;
                    }
                }
            }
        }
    }
    .credits-button {
        min-width: 8rem;
        height: 33px;
        background: $primary;
        outline: none;
        color: $white;
        border-radius: 18px;
        font-size: 1.4rem;
        margin-left: auto;
        padding: unset;
        @media screen and (min-width: $bpm-brk-3) {
            margin: 0 1.36rem;
        }
    }

    .tooltip-container {
        .Tooltip {
            top: 5rem;
            right: unset;
            left: 10rem;
        }
        &:not(:hover) {
            .Tooltip {
                display: none;
            }
        }

        .credits-amount {
            @extend .credits-button;
            min-width: 6rem;
            cursor: default;
            background: $black;
            border: 1px solid $sidebar-text-grey;
            margin: 0;
            .light-theme & {
                background: transparent;
                color: $black;
                border: 1px solid map-get($light-theme, divider-grey);
            }
            @media all and (max-width: $bpm-brk-3) {
                display: none;
            }
        }
    }

    .account-container {
        @media screen and (max-width: $bpm-brk-3) {
            display: none;
        }

        .avatar {
            clip-path: circle();
            height: 4rem;
            width: 4rem;
            margin-left: 2.5rem;
            cursor: pointer;
        }

        .avatar-dropdown-container {
            margin: 0 0 0 1rem;
            height: 2.5rem;
            width: 2.5rem;
            cursor: pointer;
            .chevron {
                margin: 0.9rem 0 0 0.65rem;
                cursor: pointer;
            }
        }
    }
    .platform,
    .volume {
        cursor: pointer;
        position: relative;
        @media screen and (max-width: $bpm-brk-3) {
            display: none;
        }
        img {
            height: 20px;
            margin-top: 3px;
            .light-theme & {
                filter: brightness(0);
            }
        }
        margin-left: 16px;
    }
    .platform-selection {
        position: absolute;
        right: 0rem;
        top: 6rem;
        opacity: 0;
        visibility: hidden;
        padding: 20px;
        border-radius: 0 0 8px 8px;
        color: white;
        background-color: #1a1a1a;
        box-shadow: -3.4px 2px 8px rgba(#000, 0.3);

        .switch-text {
            font-size: 16px;
        }
        .divider {
            background: rgba(#707070, 0.5);
            width: 100%;
            height: 1px;
            margin-top: 6px;
            margin-bottom: 17px;
        }
        .light-theme & {
            background-color: white;
            color: black;
            border: $border-grey;
        }
        .platform-selection-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            &:first-child {
                margin-bottom: 14px;
            }
            .text {
                font-weight: 500;
                margin-right: 1.4rem;
                font-size: 1.8rem;
            }
            .img {
                padding: 8px 4rem 8px 0;
                img {
                    height: 42px;
                    width: auto;
                }
            }
        }
    }
    .volume-slider {
        position: absolute;
        left: -0.9rem;
        top: 3.8rem;
        opacity: 0;
        visibility: hidden;
        border-radius: 8px;
        background-color: #000;
        .light-theme & {
            background-color: white !important;
            border: $border-grey;
        }
        .volume-control {
            width: 40px;
            height: 144px;
            display: flex;
            flex-direction: row;
            align-content: center;
            justify-content: center;
            > div {
                position: absolute;
                height: 114px;
                bottom: 10px;
            }
            .rc-slider {
                margin-right: 8px;
            }
            .rc-slider-rail {
                width: 10px !important;
                left: 2px;
            }
            .rc-slider-track {
                background-color: $primary;
                width: 10px !important;
                left: 2px;
            }
            .rc-slider-handle {
                border-color: #fff !important;
                .light-theme & {
                    border-color: $primary !important;
                }
                width: 20px !important;
                height: 20px !important;
                margin-left: -8px;
                &:focus {
                    box-shadow: 0 0 0 0 $primary;
                }
            }
        }
    }
    .switch-container {
        display: flex;
        position: relative;
        .switch {
            position: relative;
            width: 7.7rem;
            height: 3.7rem;
            -webkit-appearance: none;
            background: $button-background-grey;
            outline: none;
            border-radius: 2rem;
            // box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.2);
            margin-left: 1.36rem;
            cursor: pointer;
            .light-theme & {
                background: rgba(#eaeaea, 0.5);
            }
            &::before {
                content: '';
                width: 3rem;
                height: 3rem;
                border-radius: 2rem;
                background: $primary;
                position: absolute;
                top: 0.35rem;
                right: 0.45rem;
                // box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
                transition: 0.5s;
                transform: scale(0.7);
            }
            &:checked[type='checkbox']:before {
                right: 4rem;
            }
            @media screen and (max-width: $bpm-brk-3) {
                margin-right: 1.37rem;
            }
        }
        .night-mode-text,
        .light-mode-text {
            color: $night-theme-text;
            position: absolute;
            z-index: 99;
            width: 3.3rem;
            top: 0.77rem;
            cursor: pointer;
            font-size: 1rem;
            line-height: 1.2rem;
        }
        .night-mode-text {
            right: 3.2rem;
            visibility: visible;
            opacity: 1;
            pointer-events: none;
            @media screen and (max-width: $bpm-brk-3) {
                right: 4.5rem;
            }
        }

        .light-mode-text {
            right: 0.7rem;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            @media screen and (max-width: $bpm-brk-3) {
                right: 2.2rem;
            }
        }
    }

    // Desktop Hamburger
    .hamburger-container.desktop {
        display: unset;
        padding: 2rem;
        height: 7rem;
        margin-bottom: 2.5rem;
        margin-left: -2rem;
        .hamburger-icon {
            width: 2.2rem;
            top: 1.8rem;
            &:before {
                top: 0.9rem;
                width: 2.2rem;
            }
            &:after {
                width: 2.2rem;
            }
        }
        @media screen and (max-width: $bpm-brk-3) {
            display: none;
        }
    }

    // Mobile Hamburger
    .hamburger-container {
        display: none;
        height: 4rem;
        cursor: pointer;
        margin-bottom: 0.5rem;
        z-index: 100;
        transform: scale(0.95);
        .hamburger-icon {
            // middle line
            position: relative;
            top: 1rem;
            height: 2px;
            width: 2.5rem;
            background: $white;
            .light-theme & {
                background: #c7c7c7;
            }
            &:before {
                @extend .hamburger-icon;
                position: absolute;
                content: ' ';
                top: 2rem;
                width: 2.5rem;
            }
            &:after {
                @extend .hamburger-icon;
                position: absolute;
                content: ' ';
                bottom: 3rem;
                width: 1.5rem;
            }
        }
        &:not(.desktop) {
            @media screen and (max-width: $bpm-brk-3) {
                display: unset;
            }
        }
    }
}
.above-header-bar {
    z-index: 1002;
    top: 20px !important;
}
