.Browse {
    .soundslist-header-wrapper {
        display: flex;
        .view-all-songs {
            cursor: pointer;
            margin-left: 1.8rem;
            color: #c12ee5;
            font-size: 1.4rem;
        }
    }
    .SoundsList {
        .info.filter {
            display: none;
        }
        .end-text {
            display: none;
        }
        .list-item {
            border-bottom: none;
        }
        .icons-container {
            margin-left: 0;
        }
        .checkbox {
            display: none;
        }
        .name {
            flex: 8 1;
            min-width: unset;
            width: unset;
            .name-inner {
                font-size: 1.4rem;
                max-width: unset;
            }
        }
        .wave-form-container {
            display: none;
        }

        .name .name-inner {
            max-width: unset;
        }
    }
}
