.AlbumRow {
    .end-text {
        display: flex;
        justify-content: center;
        margin-top: 4rem;
        font-size: 16px;
        color: $white;
        .light-theme & {
            color: $black;
        }
    }
    .header {
        display: flex;
        justify-content: space-between;
        .title {
            color: $white;
            font-size: 2.2rem;
            margin-right: 1.23rem;
            font-family: 'Poppins-semi-bold';
            .light-theme & {
                color: map-get($light-theme, black);
            }
        }
        .right-container {
            margin-top: 0.5rem;
            .left-button {
                transition: opacity 0.3s ease-in-out;
                position: relative;
                padding: 1.1rem 2.2rem;
                background: $button-background-grey;
                border-radius: 50%;
                margin-right: 1.2rem;
                img {
                    position: absolute;
                    width: 1.4rem;
                    left: 32%;
                    top: 35%;
                    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(253deg) brightness(102%) contrast(101%);
                }
            }
            .right-button {
                transition: opacity 0.3s ease-in-out;
                position: relative;
                padding: 1.1rem 2.2rem;
                background: $primary;
                border-radius: 50%;
                img {
                    position: absolute;
                    width: 1.4rem;
                    left: 37%;
                    top: 35%;
                    transform: rotate(180deg);
                    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(253deg) brightness(102%) contrast(101%);
                }
            }
        }
    }

    .no-packs {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2.6rem;
        color: #fff;
        margin-top: 4rem;
        .light-theme & {
            color: map-get($light-theme, $black);
        }
    }
    .content {
        overflow-y: visible;
        &.albums-container {
            display: flex;
            overflow-x: scroll;
            @media screen and (max-width: $bpm-brk-5) {
                margin-top: -2rem;
            }
        }
        .album-and-text-container {
            margin-right: 2.64rem;
            max-width: 19.8rem;
            margin-top: 3.4rem;
            transition: all 0.15s ease-in-out;
            .title {
                transition: all 0.15s ease-in-out;
            }
            &:hover {
                .title {
                    color: $primary;
                    cursor: pointer;
                }
            }
            @media all and (max-width: $mac-15) {
                width: 150px;
            }
            .genre:hover {
                color: $primary;
                cursor: pointer;
            }

            .cover-container {
                cursor: pointer;
                position: relative;
                margin: 0rem 0 1.4rem 0;
                &:hover {
                    & .clickables-container {
                        visibility: unset;
                    }
                }
                &:not(:hover) {
                    // .heart {
                    //     display: none;
                    // }
                    .play-button {
                        display: none;
                    }
                }
                .clickables-container {
                    position: absolute;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    padding: 0.8rem;
                    visibility: hidden;
                    .heart {
                        height: 3rem;
                        cursor: pointer;
                        &:not(.no-fill) {
                            filter: $white-filter;
                        }
                    }
                    .ellipses {
                        position: absolute;
                        font-size: 3rem;
                        top: 0.5rem;
                        right: 0.5rem;
                        color: $primary;
                        font-size: 3.6rem;
                        line-height: 0rem;
                        letter-spacing: 0.2rem;
                        padding: 1.6rem;
                        cursor: pointer;
                    }
                    .Dropdown {
                        position: absolute;
                        right: -1.4rem;
                        top: 2.4rem;
                        z-index: 99;
                        // TODO: Remove and update overflow on container to work properly
                        transform: scale(0.9);
                    }
                }
                .play-button {
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    left: 50%;
                    cursor: pointer;
                    width: 4rem;
                }
                .cover {
                    height: 177px;
                    width: 177px;
                    border-radius: 0.5rem;
                    @media all and (max-width: $mac-15) {
                        height: 150px;
                        width: 150px;
                    }
                }
            }
        }

        .text-container {
            .title {
                color: $white;
                font-size: 1.6rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                .light-theme & {
                    color: map-get($light-theme, black);
                }
                @media (hover: hover) {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .genre {
                margin-top: 0.38rem;
                color: $night-theme-text;
                font-size: 1.4rem;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1; /* number of lines to show */
                line-height: 1.4rem; /* fallback */
                height: 1.5rem;
                @media (hover: hover) {
                    &:hover {
                        text-decoration: underline;
                    }
                }
                .light-theme & {
                    color: #969fa8;
                }
            }
            .label {
                @extend .genre;
                display: none;
                margin-top: 3em;
            }
        }
    }
    &.defaultExpanded {
        .header {
            display: flex;
            justify-content: center;
            .right-container {
                display: none;
            }
        }
        .albums-container {
            display: grid !important;
            grid-template-columns: repeat(auto-fill, minmax(22rem, 195px));
            justify-content: center;
            padding-left: 2.64rem;
            @media all and (min-width: $max-width) {
                grid-template-columns: repeat(7, minmax(7.7rem, 24rem));
            }
            .album-and-text-container {
                margin-top: 4.4rem;
            }
        }
        .Title {
            .view-all {
                display: none;
            }
        }
    }
}

// 650px
@media screen and (max-width: $bpm-brk-5) {
    .AlbumRow {
        .header {
            .right-container {
                display: none;
                .left-button {
                    padding: 11px 20px;
                    & img {
                        left: 29%;
                        top: 29%;
                        transform: scale(0.85);
                    }
                }
                .right-button {
                    padding: 11px 20px;
                    & img {
                        left: 37%;
                        top: 30%;
                        transform: rotate(180deg) scale(0.85);
                    }
                }
            }
            .left-container {
                .Title {
                    .subtext {
                        max-width: 32rem;
                        @media screen and (max-width: $bpm-brk-5) {
                            margin-top: 0.4rem;
                        }
                    }
                }
            }
        }
        .content {
            .album-and-text-container {
                margin-right: 1.2rem;
            }
        }
    }
}

@media screen and (max-width: $bpm-brk-6) {
    .AlbumRow {
        .header {
            .left-container {
                .Title {
                    .container {
                        .header {
                            white-space: nowrap;
                            font-size: 1.8rem;
                        }
                        .view-all {
                            white-space: nowrap;
                            font-size: 1.4rem;
                        }
                    }
                    .subtext {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}
