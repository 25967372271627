.toast-top-right {
    top: calc(#{$header-height} + 20px);
    right: 12px;
    top: 12px;
    position: fixed;
    z-index: 999999;
}
#toast-container > .toast {
    background-image: none !important;
    box-shadow: 0 0 10px rgba($black, 0.1) !important;
    color: $black;
    position: relative;
    background-color: rgba(#c12ee5, 0.8);
    padding: 15px 15px 15px 50px;
    border-radius: 3px;
    width: 300px;
    margin-bottom: 1rem;
}

.toast-message {
    color: #ffffff;
    font-size: 14px;
}

.IndividualPack {
    .dropdownButtons {
        display: none !important;
    }
}
