.AccountHeader {
    padding: 0 4rem;
    background: $black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 68px;
    @media all and (max-width: $bpm-brk-5) {
        padding: 32px 3rem;
    }
    .light-theme & {
        background: $white;
        border-bottom: 1px solid map-get($light-theme, light-grey-secondary);
    }

    .logo {
        cursor: pointer;
        width: 14rem;
        @media all and (max-width: $bpm-brk-5) {
            margin-right: 2rem;
            width: 13rem;
        }
        @media all and (max-width: $bpm-brk-4) {
            display: none;
        }
        &-mobile {
            display: none;
            cursor: pointer;

            @media all and (max-width: $bpm-brk-4) {
                display: unset !important;
                width: 4rem;
                margin-right: 4rem;
            }
            @media all and (max-width: $bpm-brk-5) {
                width: 3.5rem;
                margin-right: 2rem;
            }
        }
    }
    .nav-btns {
        .account {
            cursor: pointer;
            font-size: 14px;
            color: $sidebar-text-grey;
            margin-right: 7rem;
            position: relative;
            @media all and (max-width: $bpm-brk-5) {
                margin-right: 2rem;
            }
            .light-theme & {
                color: $sidebar-text-grey;
            }
            &:hover,
            &.selected {
                color: $white;
                .light-theme & {
                    color: $black;
                }
                &:after {
                    @media all and (max-width: $bpm-brk-5) {
                        width: 8rem;
                        left: -0.5rem;
                    }
                    content: '';
                    top: 4.8rem;
                    position: absolute;
                    height: 2px;
                    background: $primary;
                    width: 150%;
                    left: -1.2rem;
                }
            }
        }
        .billing {
            cursor: pointer;
            font-size: 14px;
            color: $sidebar-text-grey;
            position: relative;
            margin-right: 7rem;
            .light-theme & {
                color: $sidebar-text-grey;
            }
            @media all and (max-width: $bpm-brk-5) {
                margin-right: 2rem;
            }
            &:hover,
            &.selected {
                color: $white;
                .light-theme & {
                    color: $black;
                }
                &:after {
                    @media all and (max-width: $bpm-brk-5) {
                        width: 8rem;
                        left: -1rem;
                    }
                    content: '';
                    top: 4.8rem;
                    position: absolute;
                    height: 2px;
                    background: $primary;
                    width: 10rem;
                    left: -2.3rem;
                }
            }
        }
        .browse {
            cursor: pointer;
            font-size: 14px;
            color: $sidebar-text-grey;
            position: relative;
            @media all and (max-width: $bpm-brk-6) {
                display: none;
            }
            .light-theme & {
                color: $sidebar-text-grey;
            }
            &:hover,
            &.selected {
                color: $white;
                .light-theme & {
                    color: $black;
                }
                &:after {
                    @media all and (max-width: $bpm-brk-5) {
                        width: 8rem;
                        left: -1rem;
                    }
                    content: '';
                    top: 4.8rem;
                    position: absolute;
                    height: 2px;
                    background: $primary;
                    width: 10rem;
                    left: -2.3rem;
                }
            }
        }
    }
    .account-container {
        transform: scale(0.85);
        margin-right: 0.5px;
        cursor: pointer;
        .avatar {
            -webkit-clip-path: circle();
            clip-path: circle();
            height: 4rem;
            width: 4rem;
            cursor: pointer;
            margin-right: 1.3rem;
        }
        .chevron {
            margin-left: 0.35rem;
        }
        .AccountMenu .container {
            right: 0rem;
            position: absolute;
            top: 5rem;
        }
    }
}
